import React from "react";
import Link from "next/link";
import { Grid } from "@mui/material";
import styles from "./styles/unisearch_university.module.scss";
import { getAssets } from "@unisearch/helpers";
import Image from "next/legacy/image";
import img_university from "../../../public/assets/images/home/unisearch-university.webp";

export const UnisearchUniversity = () => {
  return (
    <section className={styles.section_unisearch_university}>
      <div className="container">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item sm={12} md={6}>
            <div className={`${styles.title_wrapper}`}>
              <h2 className={`${styles.title} fw400`}>
                UniSearch for
                <br />
                <span className="mark">University</span>
              </h2>
              <p className={`${styles.sub_title}`}>
                With our in-depth market insight of student source markets, and
                the smart technology powering UniSearch, we connect you to the
                most promising students for your university from around the
                world, turning diverse best-fit prospects into successful
                applicants
              </p>

              <Link legacyBehavior href="/university">
                <a className="btn btn-secondary btn-bg">University</a>
              </Link>
            </div>
          </Grid>

          <Grid item sm={12} md={6}>
            <div className={styles.uni_wrapper}>
              <img
                className={styles.oval}
                src={getAssets("assets/images/home/oval2.webp")}
                alt="Oval"
                title="Oval"
              />
              <img
                className={styles.square}
                src={getAssets("assets/images/home/square.webp")}
                alt="Square"
                title="Square"
              />
              <div className={styles.image_wrapper}>
                <Image
                  layout="responsive"
                  src={img_university}
                  alt="Unisearch study international"
                  title="Unisearch study international"
                  placeholder="blur"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default UnisearchUniversity;
